#smyth-main blockquote.quote, #smyth-main blockquote.quote p {
  margin: 0;
  display: inline;
}

#smyth-main div.citquote {
  display: inline;
}

#smyth-main div.citbibl {
  margin-right: -.25em;
  display: inline;
  text-align: left !important;
  font-size: 100% !important;
}

#smyth-main table {
  border-collapse: collapse;
  width: 100%;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
  margin: 20px 0;
}

#smyth-main td {
  border-top: 1px solid;
  border-bottom: 1px solid;
  padding: 2px 6px !important;
}

#smyth-main .foreign {
  color: #2f4f4f;
  font-size: 110%;
}

#smyth-main .term, #smyth-main .gloss, #smyth-main i {
  color: #008b8b;
  font-style: italic;
  font-weight: 500;
}

#smyth-main .gloss {
  display: inline;
}

#smyth-main h1 {
  text-align: left;
  margin-bottom: .8em;
  font-size: 2em;
}

#smyth-main .section:after {
  content: "፨";
  width: 100%;
  height: 20px;
  text-align: center;
  margin: 1.2em 0 1em;
  font-size: 150%;
  display: block;
}

#smyth-main .section:last-child:after {
  display: none;
}

#smyth-main h4:first-child {
  margin-top: .8em;
  padding-top: .5em;
}

#smyth-menu a.current {
  color: #000;
  font-weight: 500;
}

#smyth-main b {
  font-weight: 500;
}

#smyth-main h5, #smyth-main h6, #smyth-main h7 {
  margin: .8em 0 .7em;
}

#smyth-main h3, #smyth-main h4, #smyth-main h5 {
  font-weight: 500;
}

#smyth-menu h2 {
  text-align: left;
}

#smyth-main p.right {
  display: none;
}

#smyth-menu ul, #smyth-menu li {
  margin: 0;
  padding: 0;
  list-style: none;
}

#smyth-menu {
  height: 60vh;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}

#smyth-menu::-webkit-scrollbar {
  display: none;
}

#smyth-sticky {
  width: 310px;
  position: fixed;
}

#smyth-menu {
  padding-bottom: 150px;
}

#smyth-menu ul ul {
  margin-left: 20px;
}

#smyth-menu ul ul ul ul {
  display: none;
}

#smyth-menu a {
  color: #696969;
}

#smyth-main {
  min-height: 75vh;
}

#smyth-main .center h1 {
  text-align: center !important;
}

#smyth-overlay {
  height: 60vh;
  width: 100%;
  pointer-events: none;
  background-image: linear-gradient(#fff0 75%, #fff 101%);
  position: absolute;
  top: 0;
  left: 0;
}

#smyth-footer {
  margin-top: 25px;
}

#smyth-main h2, #smyth-main h3, #smyth-main h4, #smyth-main h5, #smyth-main h6, #smyth-main h7 {
  text-align: center;
  color: #2f4f4f;
  font-weight: 600;
}

#smyth-main h1 {
  text-align: center;
}

#smyth-main .toc {
  list-style-type: none;
}

.noUi-target, .noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: #0000;
  -webkit-user-select: none;
  user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
}

.noUi-base, .noUi-connects {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: relative;
}

.noUi-connects {
  z-index: 0;
  overflow: hidden;
}

.noUi-connect, .noUi-origin {
  will-change: transform;
  z-index: 1;
  height: 100%;
  width: 100%;
  transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-style: flat;
  position: absolute;
  top: 0;
  right: 0;
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
  left: 0;
  right: auto;
}

.noUi-vertical .noUi-origin {
  width: 0;
  top: -100%;
}

.noUi-horizontal .noUi-origin {
  height: 0;
}

.noUi-handle {
  backface-visibility: hidden;
  position: absolute;
}

.noUi-touch-area {
  height: 100%;
  width: 100%;
}

.noUi-state-tap .noUi-connect, .noUi-state-tap .noUi-origin {
  transition: transform .3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

.noUi-horizontal {
  height: 18px;
}

.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  top: -6px;
  right: -17px;
}

.noUi-vertical {
  width: 18px;
}

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  bottom: -17px;
  right: -6px;
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -17px;
  right: auto;
}

.noUi-target {
  background: #fafafa;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px #f0f0f0, 0 3px 6px -5px #bbb;
}

.noUi-connects {
  border-radius: 3px;
}

.noUi-connect {
  background: #3fb8af;
}

.noUi-draggable {
  cursor: ew-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}

.noUi-handle {
  cursor: default;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;
}

.noUi-active {
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ddd, 0 3px 6px -3px #bbb;
}

.noUi-handle:before, .noUi-handle:after {
  content: "";
  height: 14px;
  width: 1px;
  background: #e8e7e6;
  display: block;
  position: absolute;
  top: 6px;
  left: 14px;
}

.noUi-handle:after {
  left: 17px;
}

.noUi-vertical .noUi-handle:before, .noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  top: 14px;
  left: 6px;
}

.noUi-vertical .noUi-handle:after {
  top: 17px;
}

[disabled] .noUi-connect {
  background: #b8b8b8;
}

[disabled].noUi-target, [disabled].noUi-handle, [disabled] .noUi-handle {
  cursor: not-allowed;
}

.noUi-pips, .noUi-pips * {
  box-sizing: border-box;
}

.noUi-pips {
  color: #999;
  position: absolute;
}

.noUi-value {
  white-space: nowrap;
  text-align: center;
  position: absolute;
}

.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}

.noUi-marker {
  background: #ccc;
  position: absolute;
}

.noUi-marker-sub, .noUi-marker-large {
  background: #aaa;
}

.noUi-pips-horizontal {
  height: 80px;
  width: 100%;
  padding: 10px 0;
  top: 100%;
  left: 0;
}

.noUi-value-horizontal {
  transform: translate(-50%, 50%);
}

.noUi-rtl .noUi-value-horizontal {
  transform: translate(50%, 50%);
}

.noUi-marker-horizontal.noUi-marker {
  width: 2px;
  height: 5px;
  margin-left: -1px;
}

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}

.noUi-pips-vertical {
  height: 100%;
  padding: 0 10px;
  top: 0;
  left: 100%;
}

.noUi-value-vertical {
  padding-left: 25px;
  transform: translate(0, -50%);
}

.noUi-rtl .noUi-value-vertical {
  transform: translate(0, 50%);
}

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}

.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}

.noUi-tooltip {
  color: #000;
  text-align: center;
  white-space: nowrap;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  padding: 5px;
  display: block;
  position: absolute;
}

.noUi-horizontal .noUi-tooltip {
  bottom: 120%;
  left: 50%;
  transform: translate(-50%);
}

.noUi-vertical .noUi-tooltip {
  top: 50%;
  right: 120%;
  transform: translate(0, -50%);
}

.noUi-horizontal .noUi-origin > .noUi-tooltip {
  bottom: 10px;
  left: auto;
  transform: translate(50%);
}

.noUi-vertical .noUi-origin > .noUi-tooltip {
  top: auto;
  right: 28px;
  transform: translate(0, -18px);
}

/*# sourceMappingURL=index.56ea5d6c.css.map */
